export default [
  {
    key: "1",
    name: "报表",
    icon: "bar-chart",
    submenus: [
      { key: "/inventory_report", name: "库存报表" },
      { key: "/stock_in_report", name: "入库报表" },
      { key: "/stock_out_report", name: "出库报表" },
      { key: "/inventory_warning", name: "库存预警" },
    ],
  },
  {
    key: "2",
    name: "基础数据",
    icon: "table",
    submenus: [
      { key: "/warehouse", name: "仓库管理" },
      { key: "/area", name: "库区管理" },
      { key: "/location", name: "库位管理" },
      { key: "/client", name: "客户管理" },
      { key: "/category", name: "产品分类" },
      { key: "/material", name: "产品管理" },
    ],
  },
  {
    key: "3",
    name: "作业类型",
    icon: "appstore",
    submenus: [
      { key: "/stock_in_record", name: "入库" },
      { key: "/stock_out_record", name: "出库" },
      { key: "/location_transfer_record", name: "移库" },
    ],
  },
  {
    key: "4",
    name: "系统管理",
    icon: "team",
    submenus: [
      { key: "/role", name: "角色管理" },
      { key: "/user_account", name: "员工账号" },
      { key: "/label_config", name: "标签配置" },
      { key: "/stock_in_label", name: "入库标签打印" },
      { key: "/mobile_download", name: "移动端下载" },
    ],
  },
];
